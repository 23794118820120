import { DataTableCol } from "../../../components/tables/types";

export const AttendanceDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'firstName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Phone Number',
        control: 'phone',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Role',
        control: 'roleName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Start Date',
        control: 'startDate',
        canShowColumn: true
    },
    {
        title: 'End Date',
        control: 'endDate',
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: false,
        canShowColumn: true,
        isAccessButton:true
    },
];