import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { UserService } from "../../../app/service/user.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Personal");
  const navigation = useNavigate();
  const [userInfo, setUserInfo] = useState<any>({});

  const [personalInfo, setPersonalInfo] = useState<any>({});
  const [educationalInfo, setEducationalInfo] = useState<any>({});
  const [contactInfo, setContactInfo] = useState<any>({});

  // State for Change Password
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    getUserInfo(data?.id);
  }, []);

  const getUserInfo = async (id: any) => {
    let payload = { userId: id };
    try {
      const res = await UserService.userInfo(payload);
      let response = res?.data;
      if (res.status === "Success") {
        setUserInfo(response);

        const personalInfo = response.personalInfo || {};
        if (personalInfo.dob) {
          personalInfo.dob = moment(personalInfo.dob).format("YYYY-MM-DD");
        }

        setPersonalInfo(personalInfo);
        setEducationalInfo(response.educationalInfo || {});
        setContactInfo(response.contactInfo || {});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    try {
      let payload;
      switch (activeLink) {
        case "Personal":
          payload = { personalInfo, userId: userInfo._id };
          await UserService.updatePersonalInfo(payload);
          break;
        case "Educational":
          payload = { educationalInfo, userId: userInfo._id };
          await UserService.updatePersonalInfo(payload);
          break;
        case "Contact":
          payload = { contactInfo, userId: userInfo._id };
          await UserService.updatePersonalInfo(payload);
          break;
      }
      toast.success("Profile updated successfully");
      const loginData: any = sessionStorage.getItem("loginData");
      const data = JSON.parse(loginData);
      getUserInfo(data?.id);
      setIsOpen(false);
    } catch (err) {
      console.error("Error in handleSave:", err);
      toast.error("Failed to update profile");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    switch (activeLink) {
      case "Personal":
        setPersonalInfo({ ...personalInfo, [name]: value });
        break;
      case "Educational":
        setEducationalInfo({ ...educationalInfo, [name]: value });
        break;
      case "Contact":
        setContactInfo({ ...contactInfo, [name]: value });
        break;
      case "Change Password":
        if (name === "currentPassword") setCurrentPassword(value);
        else if (name === "newPassword") setNewPassword(value);
        else if (name === "confirmPassword") setConfirmPassword(value);
        break;
    }
  };

  const handleClick = (link: string) => {
    setActiveLink(link);
  };



  const handleClickProfile = async () => {
    Swal.fire({
      title: "Upload Profile Picture",
      html: '<input type="file" id="file-upload" class="swal2-file" accept="image/*" />',
      showCancelButton: true,
      confirmButtonText: "Upload",
      preConfirm: () => {
        const fileInput = document.getElementById("file-upload") as HTMLInputElement;
        if (!fileInput?.files?.length) {
          Swal.showValidationMessage("Please select an image to upload");
          return null;
        }
        return fileInput.files[0];
      },
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        const file = result.value;
        const loginData: any = sessionStorage.getItem("loginData");
        const data = JSON.parse(loginData);
        const formData = new FormData();
        formData.append("profileUrl", file);
        formData.append("userId", data.id);
        const res = await UserService.uploadProfile(formData);
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          getUserInfo(data?.id);
          toast.success("Profile picture uploaded successfully");
        }
      }
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-5 py-5">
            <div className="row">
              <div className="col-md-3 text-center">
                <h4>
                  <b>My Profile</b>
                </h4>
              </div>
              <div className="col-md-9 text-end">
                {isOpen ? (
                  <div>
                    <button className="btn btn-primary" onClick={handleSave}>
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-secondary"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    className="btn btn-danger"
                    onClick={() => setIsOpen(true)}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 shadow rounded-3 mt-4 mb-4 pb-4">
                <div className="ourcoursescard py-3">
                  <div className="profile-layout">
                    <img
                      src={userInfo?.profileUrl}
                      className="rounded mt-3 text-center pb-3 d-flex justify-content-center profile-img"
                      style={{ margin: "auto" }}
                    />
                    <span
                      className="testing-camera rounded text-center d-flex justify-content-center"
                      onClick={handleClickProfile}
                    >
                      <i
                        className="bi bi-camera ms-2"
                        style={{ fontSize: "40px" }}
                      ></i>
                    </span>
                  </div>
                  <div className="d-flex border-bottom justify-content-center">
                    <span className="pt-2">Name: </span>
                    <h6 className="mt-1 me-2 pb-2 pt-2">
                      {userInfo?.firstName || "-"}
                    </h6>
                  </div>
                  <div className="d-flex border-bottom justify-content-center">
                    <span className="pt-2">Phone:</span>
                    <h6 className="mt-1 me-2 pb-2 pt-2">
                      {userInfo?.phone || "-"}
                    </h6>
                  </div>
                  <div className="d-flex border-bottom justify-content-center">
                    <span className="pt-2">Address:</span>
                    <h6 className="mt-1 me-2 pb-2 pt-2">
                      {contactInfo?.city || "-"}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-center">
                    <span className="pt-2">Pincode:</span>
                    <h6 className="mt-1 me-2 pb-2 pt-2">
                      {contactInfo?.pincode || "-"}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-8 shadow rounded-3 mt-4 pb-4">
                <ul className="nav px-5 py-5">
                  <li className="nav-item">
                    <a
                      className={`nav-link nav_user ${activeLink === "Personal" ? "active_one" : ""}`}
                      href="#"
                      onClick={() => handleClick("Personal")}
                    >
                      Personal
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav_user ${activeLink === "Educational" ? "active_one" : ""}`}
                      href="#"
                      onClick={() => handleClick("Educational")}
                    >
                      Educational
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav_user ${activeLink === "Contact" ? "active_one" : ""}`}
                      href="#"
                      onClick={() => handleClick("Contact")}
                    >
                      Contact
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav_user ${activeLink === "Change Password" ? "active_one" : ""}`}
                      href="#"
                      onClick={() => handleClick("Change Password")}
                    >
                      Change Password
                    </a>
                  </li>
                </ul>

                {/* Personal Info */}
                {activeLink === "Personal" && (
                  <div className="row px-5">
                    <div className="col-md-6">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        value={personalInfo.firstName || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        value={personalInfo.lastName || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Email</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={personalInfo.email || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        name="dob"
                        className="form-control"
                        value={personalInfo.dob || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                  </div>
                )}

                {/* Educational Info */}
                {activeLink === "Educational" && (
                  <div className="row px-5">
                    <div className="col-md-6">
                      <label>Degree</label>
                      <input
                        type="text"
                        name="degree"
                        className="form-control"
                        value={educationalInfo.degree || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>University</label>
                      <input
                        type="text"
                        name="university"
                        className="form-control"
                        value={educationalInfo.university || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                  </div>
                )}

                {/* Contact Info */}
                {activeLink === "Contact" && (
                  <div className="row px-5">
                    <div className="col-md-6">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        value={contactInfo.city || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Pincode</label>
                      <input
                        type="text"
                        name="pincode"
                        className="form-control"
                        value={contactInfo.pincode || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>State</label>
                      <input
                        type="text"
                        name="state"
                        className="form-control"
                        value={contactInfo.state || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Country</label>
                      <input
                        type="text"
                        name="country"
                        className="form-control"
                        value={contactInfo.country || ""}
                        onChange={handleChange}
                        disabled={!isOpen}
                      />
                    </div>
                  </div>
                )}

                {/* Change Password Info */}
                {activeLink === "Change Password" && (
                  <div className="row px-5">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="currentPassword" className="form-label">Current Password</label>
                        <input
                          type="password"
                          id="currentPassword"
                          name="currentPassword"
                          className="form-control"
                          value={currentPassword}
                          onChange={handleChange}
                          disabled={!isOpen}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="newPassword" className="form-label">New Password</label>
                        <input
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          className="form-control"
                          value={newPassword}
                          onChange={handleChange}
                          disabled={!isOpen}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">

                      <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          className="form-control"
                          value={confirmPassword}
                          onChange={handleChange}
                          disabled={!isOpen}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
