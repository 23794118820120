import { DataTableCol } from "../../../components/tables/types";

export const RegisterDataGridCols: DataTableCol[] = [
    {
        title: 'Date',
        control: 'createdAt',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'emailAddress',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Phone Number',
        control: 'contactNumber',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Department',
        control: 'department',
        sortable: true,
        canShowColumn: true
    },

    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: false,
        canShowColumn: true,
        isAccessButton:true
    },
];