import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { LoaDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";

const AdminLoaList = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [registersData, setRegistersData] = useState([]);
  const [filteredRegisters, setFilteredRegisters] = useState([]);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
  });

  let usersData: any = [];

  const handleCancel = () => {
    setName("");
    setFilteredRegisters(registersData); // Reset the filtered data to the original list
  };

  const handleSearch = () => {
    const filteredData = registersData.filter((register: any) =>
      register.name.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredRegisters(filteredData);
  };
  const handleCreateUser = () => {
    navigate("/admin/createambassador");
  };

  useEffect(() => {
    getRegisters();
  }, []);
  const getRegisters = () => {
    // const data = {
    //   search: "",
    //   start: 1,
    //   count: 10,
    //   type: "ambassador",
    // };

    // AdminService.getAllUserDetails(data).then((res: any) => {
    //   if (res.status === "Success") {
    //     if (res.data.result) {
    //       const formattedData = res.data.result.map((item: any) => {
    //         return {
    //           ...item,
    //           createdAt: moment(item.createdAt).format("YYYY-MM-DD hh:mm A"),
    //         };
    //       });
    //       setRegistersData(formattedData);
    //       setFilteredRegisters(formattedData);
    //     }
    //   } else {
    //     toast.error(res.message);
    //   }
    // });
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllUserDetails(data).then((res: any) => {
      if (res.status === "Success") {
        setRegistersData(res.data);
        setFilteredRegisters(res.data); // Initialize filteredUsers with all data
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteAmbassadorInfo = (userId: string) => {
    const payload = {
      userId: userId,
    };
    AdminService.deleteRegister(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getRegisters();
      } else {
        toast.error(res.message);
      }
    });
  };
  const handleAccess = async (data: any) => {
    let payload = {
      loaEmail: data.email,
      userName: data?.firstName,
      loaCertificatePeriod: new Date(),
      loaCourseName: data?.courseName[0],
      loaStartDate: formData.startDate,
      loaEndDate: formData.endDate,
    };
    try {
      const res = await AdminService.createLoa(payload);
      if (res.status === "Success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error("Error generating loa certificate:", error);
      toast.error("An error occurred while generating the loa certificate.");
    } finally {
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>LOA Data</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={() => handleSearch()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              {/* <div className="col-md-6 mt-3">
                <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={handleCreateUser}
                  >
                    Create ambassador
                  </button>
                </div>
              </div> */}
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredRegisters}
                TableCols={LoaDataGridCols}
                deleteAmbassadorInfo={deleteAmbassadorInfo}
                setFormData={setFormData}
                handleAccessData={handleAccess}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoaList;
