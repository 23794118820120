import React from 'react';

const HomeTable = () => {
  const tableData = [
    { id: 1, title: 'Project Manager', task: 'Review project proposals', status: 'Active' },
    { id: 2, title: 'Developer', task: 'Develop frontend module', status: 'Inactive' },
    { id: 3, title: 'Designer', task: 'Create UI/UX designs', status: 'Active' },
  ];

  return (
    <div className="mt-4">
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Title</th>
            <th scope="col">Task</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((user, index) => (
            <tr key={user.id}>
              <th scope="row">{index + 1}</th>
              <td>{user.title}</td>
              <td>{user.task}</td>
              <td>{user.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HomeTable;
