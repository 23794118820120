import React, { useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { courses } from "../../../config";

const CreateLmsVideos = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    title: "",
    courseName: "",
    uploadVideo: '', // Change to null to handle file upload
  });
  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // const handleFileInput = (e: any) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setFormData((prevState: any) => ({
  //       ...prevState,
  //       uploadVideo: file,
  //     }));
  //     setErrors((prevErrors: any) => ({
  //       ...prevErrors,
  //       uploadVideo: "",
  //     }));
  //   }
  // };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.title) {
      newErrors.title = "Title is required";
    }

    if (!formData.courseName) {
      newErrors.courseName = "Course name is required";
    }

    if (!formData.uploadVideo) {
      newErrors.uploadVideo = "Upload Video is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;


    AdminService.createLms(formData).then((res) => {
      if (res.status === "Fail") {
        toast.error(res.error);
      } else {
        toast.success("LMS Video created successfully");
        navigate("/support/lmsvideolist");
      }
    }).catch((err) => {
      toast.error("An error occurred while creating LMS Video");
    });
  };

  const handleCancel = () => {
    navigate("/support/lmsvideolist");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Create LMS Videos</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Add title</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.title ? "is-invalid" : ""
                    }`}
                    placeholder="Add title"
                    name="title"
                    onChange={handleInput}
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Video Url</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.uploadVideo ? "is-invalid" : ""
                    }`}
                    placeholder="Video Url"
                    name="uploadVideo"
                    onChange={handleInput}
                    accept="video/*" // Ensure that only video files are accepted
                  />
                  {errors.uploadVideo && (
                    <div className="invalid-feedback">{errors.uploadVideo}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Course Name</label>
                  <select
                    className={`form-control mt-2 ${
                      errors.courseName ? "is-invalid" : ""
                    }`}
                    onChange={handleInput}
                    name="courseName"
                  >
                    <option value="">Select Course</option>
                    {courses.map((ele: any, index: number) => (
                      <option value={ele.name} key={index}>
                        {ele.name}
                      </option>
                    ))}
                  </select>
                  {errors.courseName && (
                    <div className="invalid-feedback">{errors.courseName}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateLmsVideos;
